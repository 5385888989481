<template>
  <div>
    <AppSubHeader
      link='rechazo'
      title='Mi Tarjeta de Crédito'
    />
    <v-main class="mb-5">
      <v-container>

        <v-row class="justify-center">
          <v-col
            cols="12"
            lg="6"
          >
            <div class="m-portlet m-portlet--full-height m-portlet--mobile porlet-blue-border porlet-products">
              <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                  <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text">
                      Tarjeta de crédito
                    </h3>
                    <div
                      class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-left m-dropdown--align-push"
                      m-dropdown-toggle="hover"
                      aria-expanded="true"
                    >
                    </div>
                  </div>
                </div>
              </div>

              <div class="m-portlet__body pt-3">
                <div class="m-widget3">
                  <div class="m-widget3__item">
                    <div class="m-widget3__header">
                      <div class="m-widget3__user-img">
                        <img
                          class="m-widget3__img"
                          src="@/assets/img/tdc.png"
                          alt=""
                        >
                      </div>
                      <div class="m-widget3__info">
                        <span class="m-widget3__username">
                          <p class="mb-1">Ayúdanos a mejorar, cuéntanos ¿por que no te interesa adquirir la tarjeta de crédito VISA QNT?
                          </p>
                        </span>
                      </div>
                    </div>
                    <div class="m-widget3__body">
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="reject"
                      >
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              class="py-0"
                            >
                              <v-textarea
                                v-model.trim="comment"
                                outlined
                                :counter="255"
                                maxlength="255"
                                required
                                :rules="[
                                    v => !!v || 'Ingresa un comentario',
                                    v => (v && v.trim().split(' ').length >= 3) || 'Por favor escribe un comentario valido',
                                    v => (v && v.length <= 255) || 'Por favor escribe un comentario con menos de 255 caracteres'
                                  ]"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                          <v-row class="justify-end">
                            <v-col
                              cols="12"
                              md="6"
                              class="pt-0"
                            >
                              <v-btn
                                color="secondary"
                                block
                                type="submit"
                              >
                                ENVIAR RESPUESTA
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

      </v-container>
      <ErrorFormAlert :errors="errors" />
    </v-main>
  </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import ErrorFormAlert from "@/components/ui/ErrorFormAlert";

export default {
  name: "CreditCardRejectPage",
  components: {
    AppSubHeader, ErrorFormAlert
  },
  data () {
    return {
      valid: true,
      comment: '',
      errors: null,
    }
  },
  created () {
    if (!this.creditCard) {
      this.$store.dispatch("products/getQntProducts");
    }
  },
  methods: {
    ...mapActions('products', ['rejectCreditCard', "getQntProducts"]),
    async reject () {
      if (this.$refs.form.validate()) {
        try {
          await this.rejectCreditCard({ ...this.creditCard, rejectedReason: this.comment });
          this.getQntProducts();
          this.$router.push({ name: 'index' });
        } catch (error) {
          console.log(error)
          this.errors = error;
        }
      }
      return 1
    }
  },
  computed: {
    ...mapGetters({ creditCard: 'products/activeCreditCard', }),
  }
};
</script>

<style lang="scss" scoped>
.m-widget3 {
  .m-widget3__item {
    .m-widget3__header {
      display: flex;
      .m-widget3__info {
        align-self: center;
        .m-widget3__username {
          a {
            color: #575962;
            text-decoration: underline;
          }
        }
      }
    }
    .m-widget3__body {
      .m-widget3__text {
        color: #414141;
        font-size: 1.3rem;
      }
    }
    .m-widget3__user-img {
      align-self: auto;
      .m-widget3__img {
        width: 4.2rem;
        border-radius: 0px;
      }
    }
  }
}

.m-dropdown[m-dropdown-toggle="hover"] {
  padding: 8px 5px;
  a {
    text-decoration: none;
  }
}
</style>